import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";

import { Box, Typography } from "@mui/material";

import InfoCard from "../../../components/Cards/InfoCard";
import CardSlider from "../../../components/CardSlider/CardSlider";
import DashboardCardSkeleton from "../../../components/Skeletons/DashboardCardSkeleton";
import ThemedButton from "../../../components/ThemedButton";
import { useBranding, useMemberContracts } from "../../../hooks";

const CurrentPackage = () => {
  const { t } = useTranslation();
  const { user, apiClient, userHasPendingPayments, contractCharges } = useOutletContext();

  const { data: memberPackages, isLoading } = useMemberContracts({
    apiClient,
    memberContracts: user?.contracts,
    memberId: user?.id,
    userHasPendingPayments,
    contractCharges,
  });

  const packageCardItems = memberPackages.map((item) => (
    <InfoCard
      key={item.id}
      fullWidth
      bgColor="customColors.bg2"
      title={item.name}
      subTitle={{
        text: t("packagesModule.myPackages"),
        target: "/settings/subscriptions",
      }}
      data={[
        {
          title: t("packagesModule.packagePrice"),
          value: { text: item.formattedPrice, isLink: false, target: null },
        },
        {
          title: "",
          value: {
            text: "",
            isLink: false,
            target: null,
          },
        },
        {
          title: t("purchaseDate"),
          value: {
            text: item.startDate,
            isLink: false,
            target: null,
          },
        },
        {
          title: t("validTill"),
          value: {
            text: item.expires_at,
            isLink: false,
            target: null,
          },
        },
      ]}
    />
  ));

  let element = null;

  if (isLoading) element = <DashboardCardSkeleton />;
  else {
    if (packageCardItems.length > 0) element = <CardSlider cards={packageCardItems} />;
    else element = <NoPackagesCard />;
  }

  return (
    <Box sx={{ flex: 1, minWidth: "18rem", marginBottom: { xs: 2, md: 0 } }}>
      <Typography variant="h5" component="h2" fontWeight="fontWeightBold" sx={{ marginBottom: 1 }}>
        {t("dashboardModule.currentPackage")}
      </Typography>
      {element}
    </Box>
  );
};

export default CurrentPackage;

const NoPackagesCard = () => {
  const branding = useBranding();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handlenavigate = () => navigate("/packages");

  return (
    <Box
      sx={{
        backgroundColor: "customColors.bg2",
        border: "1px solid",
        borderColor: "customColors.border",
        borderRadius: 1.5,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
        justifyContent: "end",
        height: "17rem",
        padding: 2,
      }}
    >
      {branding && <Box component="img" src={branding.assets?.identityGraphicOutline} width={50} />}
      <Typography
        variant="body1"
        fontWeight="fontWeightMedium"
        sx={{ width: "90%", textAlign: "center" }}
      >
        {t("dashboardModule.notSubToPckg")}
      </Typography>
      <ThemedButton
        variant="contained"
        fullWidth
        withIcon
        onClick={handlenavigate}
        sx={{ marginTop: 1, justifyContent: "space-between" }}
      >
        {t("dashboardModule.subscribeToPckg")}
      </ThemedButton>
    </Box>
  );
};