export class HyperPayPayment {
  constructor({ config, dispatch, apiClient }) {
    this.config = {
      startDate: config.startDate,
      signUpDate: config.signUpDate,
      memberId: config.memberDetails.id,
      clubId: config.memberDetails.clubId,
      itemDetails: config.itemDetails,
    };
    this.dispatch = dispatch;
    this.apiClient = apiClient;
    this.publicUrl = config.publicUrl;
  }

  async pay() {
    try {
      this.dispatch({ type: "SET_LOADING", payload: true });
      let endpoint = "";
      const transactionPayload = {
        returnUrl: `${this.publicUrl}/order-complete`,
        memberId: this.config.memberId,
      };

      if (this.config.itemDetails.type === "paymentPlan") {
        endpoint = "/card/payment/membership";
        transactionPayload.paymentPlanId = this.config.itemDetails.id;
        transactionPayload.startDate = this.config.startDate;
        transactionPayload.saveCard = this.config.itemDetails.automaticRenew;
      } else if (this.config.itemDetails.type === "ptSession") {
        endpoint = "/card/payment/product";
        transactionPayload.productId = this.config.itemDetails.id;
      }

      const paymentResponse = await this.apiClient.post(endpoint, transactionPayload);

      window.location.href = paymentResponse.data.url;
    } catch (error) {
      console.log("HyperPay Payment Error: ", error);

      let message = "somethingWentWrong";
      if (error.response.data.message.includes("address data")) message = "addressMissing";

      this.dispatch({ type: "SET_ERROR", payload: message });
    }
  }
}
