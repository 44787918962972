const ar = {
  translation: {
    welcome: "أهلا",
    lang: "English",
    en: "الإنجليزية",
    ar: "العربية",
    logout: "تسجيل الخروج",
    dashboard: "لوحة التحكم",
    ptSessions: "جلسات التدريب الشخصي",
    classes: "الحصص",
    packages: "الباقات",
    settings: "الإعدادات",
    mySubscriptions: "اشتراكاتي",
    mins: "دقيقة",
    somethingWentWrong: "حدث خطأ ما",
    incompleteAdress: "بيانات العنوان غير مكتملة",
    clickHere: "اضغط هنا",
    clickButtonBelow: "يرجى الضغط على الزر أدناه لإتمام عملية الدفع",
    updateYourAdress: " لتحديث عنوانك",
    trainer: "المدرب",
    branch: "فرع",
    male: "ذكر",
    female: "أنثى",
    saveChanges: "حفظ التغييرات",
    language: "اللغة",
    view: "عرض",
    confirm: "تأكيد",
    cancel: "إلغاء",
    purchaseDate: "تاريخ الشراء",
    canceledAt: "تم الإلغاء في",
    validTill: "صالح حتى",
    paymentMethod: "برجاء اختيار طريقة الدفع",
    proceed: "متابعة",
    total: "المجموع",
    paymentRejected: "تم رفض الدفع",
    applePayNotSupported: "Apple Pay غير مدعوم على هذا الجهاز",
    orderCompleteSuccessMsg: "لقد تم شراء باقتك بنجاح",
    orderCompleteFailedMsg: "يرجى الاتصال بالبنك الخاص بك",
    orderCompleteTitle: "تم بنجاح",
    orderFailedTitle: "حدث خطأٌ ما",
    continue: "الإستمرار",
    noSessionsAvailable: "لا توجد جلسات متاحة ، يرجى الاشتراك في باقة أولاً",
    errorPageMsg: "عذرًا ، الصفحة غير موجودة!",
    qualifications: "المؤهلات",
    startDate: "تاريخ البدء",
    aboutTrainer: "عن المدرب",
    paymentSuccess: "تم الدفع بنجاح",
    paymentFailed: "فشل الدفع",
    SA: "المملكة العربية السعودية",
    error: "خطأ",
    autoRenewalCanceled: "تم إلغاء التجديد التلقائي",
    login: {
      title: "تسجيل الدخول",
      signUp: "سجل",
      email: "البريد الإلكتروني",
      password: "كلمه السر",
      submit: "إرسال",
      forgotPassword: "نسيت كلمة المرور",
      forgotYourPassword: "هل نسيت كلمة المرور؟",
      backToLogin: "العودة إلى تسجيل الدخول",
      passwordHelperText: "كلمة السر مطلوبة",
      emailHelperText: "البريد الإلكتروني مطلوب",
      invalidEmail: "البريد الإلكتروني غير صالح",
      invalid_cred: {
        title: "بيانات الاعتماد غير صالحة",
        message:
          "حدث خطأ في البريد الإلكتروني أو كلمة المرور التي أدخلتها ، يرجى التأكد من إدخال بيانات اعتماد صالحة.",
      },
      invalid_email: {
        title: "بريد إلكتروني غير صالح",
        message:
          "حدث خطأ في عنوان البريد الإلكتروني الذي أدخلته ، يرجى التأكد من إدخال بريد إلكتروني صالح.",
      },
      emailSent: {
        title: "تم الإرسال بنجاح",
        message:
          "تم إرسال بريد إلكتروني إليك ، يرجى اتباع الخطوات الموجودة في البريد الإلكتروني لإعادة تعيين كلمة المرور الخاصة بك.",
      },
    },
    dashboardModule: {
      subscribeToPckg: "الاشتراك في باقة",
      currentPackage: "الباقة الحالية",
      notSubToPckg: "لم تشترك في أي باقة بعد",
      noAppointments: "لا توجد مواعيد",
      bookClass: "احجز حصة الآن",
      appointments: "المواعيد",
      personalTraining: "التدريب الشخصي",
      ptSessions: "جلسات التدريب الشخصي",
      noPtSessions:
        "لم تقم بشراء أي جلسات تدريب شخصي بعد. اشتَرِ جلسة الآن للحصول على تجربة تدريب شخصي فريدة من نوعها",
      latestPayments: "أحدث المعاملات",
      postedDate: "تاريخ النشر",
      pendingPayment: "ربما لا يزال الدفع قيد التنفيذ، يرجى التحقق مرة أخرى بعد 60 دقيقة",
      missingPaymentMethod: "يرجى إضافة طريقة الدفع وإلا فلن يتم تجديد اشتراكك تلقائيًا",
      addCard: "إضافة بطاقة",
    },
    calendar: {
      selectDate: "اختر التاريخ",
    },
    classesModule: {
      available: "متاح",
      full: "كامل",
      trainer: "المدرب",
      grpSize: "حجم المجموعة",
      time: "الوقت",
      duration: "المدة",
      moreDetails: "التفاصيل",
      noClasses: "لا يوجد حصص قادمة",
      searchClasses: "البحث عن حصص",
      bookClass: "حجز الحصة",
      youBookedThisClass: "لقد حجزت هذه الحصة",
      classBookedSuccess: "تم حجز الحصة بنجاح",
    },
    settingsModule: {
      userProfile: "الملف الشخصي",
      transactionHistory: "سجل المعاملات",
      subscriptions: "الاشتراكات",
      accountSettings: "إعدادات الحساب",
      savedCards: "البطاقات المحفوظة",
      cardNumber: "رقم البطاقة:",
      validTill: "صالح حتى:",
      noSavedCards: "لا توجد بطاقات محفوظة",
      uploadPhoto: "تحميل الصورة",
      yourProfilePic: "صورتك الشخصية",
      name: "الاسم",
      firstName: "الاسم الأول",
      lastName: "الاسم الثاني",
      email: "البريد الإلكتروني",
      dateOfBirth: "تاريخ الميلاد",
      mobileNumber: "رقم الهاتف",
      gender: "الجنس",
      nameIsRequired: "الرجاء ادخال الاسم الاول و الأخير",
      nameIsTooShort: "يجب أن يكون الاسم أطول من حرفين",
      invalidMobile: "يجب أن يبدأ رقم الهاتف المحمول بـ 05 ويحتوي على أرقام فقط",
      mobileLength: "يجب أن يكون رقم الهاتف المحمول 10 أرقام",
      ageValidationMsg: "يجب أن يكون العمر على الأقل 18 عامًا",
      nationalID: "رقم الهوية الوطنية",
      natIdIsRequired: "الرجاء ادخال رقم الهوية الوطنية",
      natIdLength: "يجب أن يكون رقم الهوية الوطنية 10 أرقام",
      invalidNatId: "يجب أن يحتوي رقم الهوية الوطنية على أرقام فقط",
      removeImg: "إزالة الصورة",
      currentPassword: "كلمة المرور الحالية",
      newPassword: "كلمة المرور الجديدة",
      confirmPassword: "تأكيد كلمة المرور",
      currentPasswordRequired: "كلمة المرور الحالية مطلوبة",
      enterNewPassword: "الرجاء إدخال كلمة مرور جديدة",
      enterPasswordConfirm: "الرجاء تأكيد كلمة المرور الجديدة",
      passwordMismatch: "كلمة المرور غير متطابقة",
      passwordMinLength: "يجب أن تكون كلمة المرور 4 أحرف على الأقل",
      passwordMaxLength: "يجب أن تكون كلمة المرور أقل من 40 حرفا",
      incorrectPassword: "كلمة المرور غير صحيحة",
      passwordChanged: "تم تغيير كلمة المرور بنجاح",
      item: "العنصر",
      postedDate: "تاريخ النشر",
      amountPaid: "المبلغ المدفوع",
      paymentLocation: "موقع الدفع",
      paymentType: "طريقة الدفع",
      quantity: "الكمية",
      discount: "الخصم",
      ref: "المرجع",
      receipt: "الإيصال",
      cancelSubscription: "إلغاء الاشتراك",
      confirmCancelSubscription:
        "هل أنت متأكد أنك تريد إلغاء {{name}}؟ الاشتراك سيظل سارى حتى {{expiryDate}}",
      noSubscriptions: "لا توجد اشتراكات",
      noTransactions: "لا توجد معاملات متاحة",
      subscriptionCancelled: "تم الغاء الاشتراك بنجاح, الاشتراك سارى حتى {{expiryDate}}",
      profileUpdated: "تم تحديث الملف الشخصي بنجاح",
      personalData: "البيانات الشخصية",
      addressData: "بيانات العنوان",
      country: "الدولة",
      state: "المنطقة",
      city: "المدينة",
      street: "العنوان",
      postalCode: "الرمز البريدي",
      stateIsRequired: "المنطقة مطلوبة",
      cityIsRequired: "المدينة مطلوبة",
      streetIsRequired: "العنوان مطلوب",
      postalCodeIsRequired: "الرمز البريدي مطلوب",
      profilePictureUpdated: "تم تحديث صورة الملف الشخصي بنجاح",
      deletePhotoError: "حدث خطأ أثناء حذف الصورة الشخصية",
      uploadPhotoError: "حدث خطأ أثناء تحديث الصورة الشخصية",
    },
    packagesModule: {
      purchasePackage: "شراء الباقة",
      myPackages: "باقاتي",
      packagePrice: "سعر الباقة",
      usage: "الاستخدام",
      currentPackages: "الباقات الحالية",
      renewalPackages: "باقات التجديد",
      currentSessions: "الجلسات الحالية",
      purchaseSessions: "شراء الجلسات",
    },
  },
};

export default ar;
