import React, { useCallback, useEffect, useReducer, useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";

import { format } from "date-fns";
import propTypes from "prop-types";

import MissingAdressError from "../../components/MissingAdressError";
import { useBranding } from "../../hooks";
import {
  getPaymentMethods,
  initialPaymentState,
  pay,
  paymentReducer,
} from "../../services/payments";
import Packages from "./Packages/Packages";
import PersonalTrainingSessions from "./PersonalTrainingSessions/PersonalTrainingSessions";

const PackagesParent = ({ route }) => {
  const branding = useBranding();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  const { apiClient, user } = useOutletContext();
  const [packageStartDate, setPackageStartDate] = useState("");

  const [payment, dispatch] = useReducer(paymentReducer, initialPaymentState);

  const onPackageStartDateChange = useCallback((date) => {
    setPackageStartDate(date);
  }, []);

  const onPaymentMethodChange = useCallback(
    (method) => {
      if (payment.method === method) dispatch({ type: "SET_PAYMENT_METHOD", payload: null });
      else dispatch({ type: "SET_PAYMENT_METHOD", payload: method });
    },
    [payment.method],
  );

  const initiatePayment = useCallback(
    ({ selectedPaymentMethod }) => {
      if (payment.selectedPackage) {
        dispatch({ type: "SET_ERROR", payload: null });

        const paymentConfig = {
          startDate: packageStartDate,
          signUpDate: format(new Date(), "yyyy-MM-dd"),
          gym_location: user?.club.cityId,
          payment_method: selectedPaymentMethod,
          publicUrl: branding?.publicUrl,
          navigate: navigate,
          memberDetails: {
            id: user.id,
            name: user.name,
            email: user.email,
            mobile: user.mobile,
            clubId: user.homeClubId,
            lang: i18n.language,
          },
          itemDetails: {
            id: payment.selectedPackage.id,
            type: payment.selectedPackage.itemType,
            name: payment.selectedPackage.name.en,
            amount: payment.selectedPackage.price,
            automaticRenew: payment.selectedPackage.automaticRenew ?? false,
          },
        };
        pay({ config: paymentConfig, dispatch, apiClient });
      }
    },
    [
      apiClient,
      branding?.publicUrl,
      i18n.language,
      navigate,
      packageStartDate,
      payment.selectedPackage,
      user,
    ],
  );

  useEffect(() => {
    if (payment.error) {
      if (payment.error === "addressMissing") {
        toast.custom(<MissingAdressError />, { duration: 8000 });
      } else {
        toast.error(t(payment.error));
      }
    }
  }, [payment.error, t]);

  const onCloseModal = useCallback(() => {
    dispatch({ type: "CLOSE_MODAL" });
  }, []);

  const onPurchasePackage = useCallback(
    (packageData) => {
      if (!branding?.paymentMethods) return;

      dispatch({
        type: "SET_SELECTED_PACKAGE",
        payload: {
          ...packageData,
          paymentMethods: getPaymentMethods({
            packageData,
            availableMethods: branding.paymentMethods,
          }),
        },
      });
      dispatch({ type: "SET_MODAL_OPEN", payload: true });
    },
    [branding?.paymentMethods],
  );

  return route === "packages" ? (
    <Packages
      onPackageStartDateChange={onPackageStartDateChange}
      onPaymentMethodChange={onPaymentMethodChange}
      initiatePayment={initiatePayment}
      onCloseModal={onCloseModal}
      onPurchasePackage={onPurchasePackage}
      payment={payment}
    />
  ) : (
    <PersonalTrainingSessions
      onPaymentMethodChange={onPaymentMethodChange}
      initiatePayment={initiatePayment}
      onCloseModal={onCloseModal}
      onPurchasePackage={onPurchasePackage}
      payment={payment}
    />
  );
};

PackagesParent.propTypes = {
  route: propTypes.oneOf(["pt-sessions", "packages"]).isRequired,
};

export default PackagesParent;
