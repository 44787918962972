import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import { Box, Typography } from "@mui/material";
import { format } from "date-fns";

const SavedCards = () => {
  const { t } = useTranslation();
  const { user } = useOutletContext();
  const [cards, setCards] = useState([]);

  useEffect(() => {
    if (user?.paymentSources)
      setCards(user.paymentSources.filter((item) => item.paymentSource === "CreditCard"));
    else setCards([]);
  }, [user?.paymentSources]);

  return (
    <Box
      sx={{
        padding: (theme) => theme.spacing(2, 0),
      }}
    >
      <Typography variant="subtitle1" fontWeight={500}>
        {t("settingsModule.savedCards")}
      </Typography>

      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {cards.length > 0 ? (
          cards.map((card) => {
            return (
              <Box
                key={card.id}
                sx={{
                  padding: 2,
                  borderRadius: 2,
                  backgroundColor: "customColors.bg2",
                  border: "1px solid",
                  borderColor: "customColors.border",
                  display: "flex",
                  flexDirection: "column",
                  gap: 3,
                  maxWidth: "1000px",
                }}
              >
                <Box>
                  <Typography fontWeight={600}>{t("settingsModule.cardNumber")}</Typography>
                  <Typography>{card.publicData}</Typography>
                </Box>
                <Box>
                  <Typography fontWeight={600}>{t("settingsModule.validTill")}</Typography>
                  <Typography>{format(card.validTo, "dd-MM-yyyy")}</Typography>
                </Box>
              </Box>
            );
          })
        ) : (
          <Typography>{t("settingsModule.noSavedCards")}</Typography>
        )}
      </Box>
    </Box>
  );
};

export default SavedCards;
