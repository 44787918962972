import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useOutletContext } from "react-router-dom";

import { Box, Card, Typography, useTheme } from "@mui/material";
import propTypes from "prop-types";

import DataComponent from "../DataComponent";
import StatusBadge from "../StatusBadge";
import ThemedButton from "../ThemedButton";

const ClassCard = ({ classID, title, status, details, selectedDate }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    viewport: { isMobile, isTablet },
  } = useOutletContext();

  const navigateToClass = () =>
    navigate(`/classes/${classID}`, { state: { prevRoute: `/classes?date=${selectedDate}` } });

  return (
    <Card
      sx={{
        flex: "1",
        boxShadow: "none",
        border: "1px solid",
        borderRadius: 2,
        borderColor: theme.palette.gray.light,
        padding: theme.spacing(1.3, 2.7),
        minWidth: isMobile ? "100%" : isTablet ? "calc(100% / 2 - 20px)" : "calc(100% / 3 - 20px)",
        maxWidth: isMobile ? "100%" : isTablet ? "calc(100% / 2)" : "calc(100% / 3 - 5px)",
        bgcolor: "#F7FAFF",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: theme.spacing(1.5),
          height: "65px",
        }}
      >
        <Typography variant="h6" fontWeight={600} component="h1">
          {title}
        </Typography>
        <StatusBadge status={status} />
      </Box>

      <Box>
        {details[0].value && (
          <DataComponent
            title={t(`classesModule.${details[0].name}`)}
            value={{
              text: details[0].value,
              isLink: true,
              target: `/trainer/${details[0].id}`,
              linkState: { prevRoute: `/classes?date=${selectedDate}` },
            }}
          />
        )}
        <DataComponent
          title={t(`classesModule.${details[1].name}`)}
          value={{ text: details[1].value, isLink: false, target: null }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {details.slice(2).map((item, index) => (
            <DataComponent
              key={index}
              title={t(`classesModule.${item.name}`)}
              value={{ text: item.value, isLink: false, target: null }}
            />
          ))}
        </Box>
        <ThemedButton
          variant="contained"
          fullWidth
          onClick={navigateToClass}
          disabled={status === "full"}
        >
          {t("classesModule.moreDetails")}
        </ThemedButton>
      </Box>
    </Card>
  );
};

ClassCard.propTypes = {
  classID: propTypes.oneOfType([propTypes.string, propTypes.number]).isRequired,
  title: propTypes.string.isRequired,
  status: propTypes.oneOf(["available", "full"]).isRequired,
  details: propTypes.arrayOf(propTypes.object).isRequired,
  selectedDate: propTypes.string,
};

export default ClassCard;
