import React from "react";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import { Alert, Button, CircularProgress, Typography } from "@mui/material";

import { useBranding, usePaymentMethodsManagement } from "../hooks";

const RecurringPlanWithoutPaymentMethod = () => {
  const { t, i18n } = useTranslation();
  const branding = useBranding();
  const { user, apiClient } = useOutletContext();
  const { loading, isActionRequired, addPaymentMethod } = usePaymentMethodsManagement({
    apiClient,
    user,
    returnUrl: `${branding?.publicUrl}/order-complete?status=Ok`,
  });

  if (!isActionRequired) return null;
  return (
    <Alert
      severity="error"
      action={
        <Button
          variant="contained"
          color="primary"
          size="small"
          onClick={addPaymentMethod}
          disabled={loading}
        >
          {loading ? <CircularProgress size={20} color="inherit" /> : t("dashboardModule.addCard")}
        </Button>
      }
      sx={{
        backgroundColor: "customColors.bg2",
        marginBottom: 2,
        display: "flex",
        alignItems: "center",
        "& .MuiAlert-action": {
          marginLeft: i18n.language === "en" ? "auto" : "-8px",
          marginRight: i18n.language === "en" ? "-8px" : "auto",
        },
      }}
    >
      <Typography sx={{ marginRight: 1 }} variant="body1">
        {t("dashboardModule.missingPaymentMethod")}
      </Typography>
    </Alert>
  );
};

export default RecurringPlanWithoutPaymentMethod;
