import React from "react";
import { useTranslation } from "react-i18next";

import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Link, Typography } from "@mui/material";

const MissingAdressError = () => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: 3,
        padding: 2,
        backgroundColor: "white",
        borderRadius: 2,
        width: "400px",
        maxWidth: "100%",
        boxShadow: 24,
      }}
    >
      <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
        <Typography sx={{ display: "inline" }} variant="body2">
          {t("incompleteAdress")}
        </Typography>
        <Link variant="textLink" component="a" href="/settings/user-profile">
          {t("clickHere")}
        </Link>
        <Typography sx={{ display: "inline" }} variant="body2">
          {t("updateYourAdress")}
        </Typography>
      </Box>
      <CancelIcon fontSize="large" color="primary" />
    </Box>
  );
};

export default MissingAdressError;