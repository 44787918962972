import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useOutletContext } from "react-router-dom";

import { Box, Typography, useTheme } from "@mui/material";
import { format } from "date-fns";
import propTypes from "prop-types";

import DataComponent from "../../../components/DataComponent";
import EmptyComponent from "../../../components/EmptyComponent";
import ConfirmationModal from "../../../components/Modals/ConfirmationModal";
import SubscriptionCardSkeleton from "../../../components/Skeletons/SubscriptionCardSkeleton";
import ThemedButton from "../../../components/ThemedButton";
import { useMemberContracts, useMemberProducts } from "../../../hooks";
import { cancelSubscription } from "../../../services/api";

const Subscriptions = () => {
  const { t } = useTranslation();

  const { apiClient, user, userHasPendingPayments, contractCharges } = useOutletContext();

  const { data: memberProducts, isPending: isPendingProducts } = useMemberProducts({
    apiClient,
    memberId: user?.id,
  });
  const { data: subscriptions, isLoading: isLoadingContracts } = useMemberContracts({
    apiClient,
    memberContracts: user?.contracts,
    memberId: user?.id,
    userHasPendingPayments,
    contractCharges,
  });

  let element = <SubscriptionCardSkeleton />;

  if (isLoadingContracts || isPendingProducts) {
    element = <SubscriptionCardSkeleton />;
  } else {
    if (subscriptions.length === 0 && memberProducts.length === 0) {
      element = <EmptyComponent text={t("settingsModule.noSubscriptions")} />;
    }

    if (subscriptions.length > 0 || memberProducts.length > 0) {
      element = [...subscriptions, ...memberProducts].map((item) => (
        <SubscriptionCard key={item.id} subscription={item} />
      ));
    }
  }

  return <Box sx={{ padding: (theme) => theme.spacing(2, 0) }}>{element}</Box>;
};

export default Subscriptions;

const SubscriptionCard = ({ subscription }) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isCanceledRecurringContract = subscription.isRecurring && subscription.cancelDate;

  const { apiClient, refetchMemberProfile } = useOutletContext();
  const { mutate, isPending } = useMutation({
    mutationFn: cancelSubscription,
    onSuccess: () => {
      closeConfirmationModal();
      toast.success(
        t("settingsModule.subscriptionCancelled", {
          expiryDate: format(new Date(subscription.expires_at), "dd-MM-yyyy"),
        }),
        { duration: 10000 },
      );
      if (refetchMemberProfile) refetchMemberProfile();
    },
    onError: (error) => {
      console.log(error);
      toast.error(t("somethingWentWrong"));
    },
  });

  const openConfirmationModal = () => setIsModalOpen(true);
  const closeConfirmationModal = () => setIsModalOpen(false);

  const handleCancelSubscription = () => {
    mutate({
      apiClient: apiClient,
      contractId: subscription.id,
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: isCanceledRecurringContract ? theme.palette.grey[100] : "customColors.bg2",
        padding: (theme) => theme.spacing(2),
        borderRadius: 1.5,
        marginBottom: 2,
        border: "1px solid",
        borderColor: "customColors.border",
      }}
    >
      <Box sx={{ display: "flex", gap: 2 }}>
        <Box>
          <Typography
            sx={{ opacity: isCanceledRecurringContract ? 0.8 : 1 }}
            variant="h4"
            fontWeight="fontWeightMedium"
            component="h2"
          >
            {subscription.name[i18n.language]}
          </Typography>
          <Typography
            sx={{ opacity: isCanceledRecurringContract ? 0.8 : 1 }}
            variant="body2"
            component="p"
          >
            {subscription.description[i18n.language]}
          </Typography>
        </Box>
        {/* {isCanceledRecurringContract && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: theme.palette.primary.main,
              height: "fit-content",
              padding: (theme) => theme.spacing(1, 1.5),
              borderRadius: 5,
            }}
          >
            <Typography sx={{ textAlign: "center" }} color="white" variant="body2">
              {t("autoRenewalCanceled")}
            </Typography>
          </Box>
        )} */}
      </Box>

      <Box sx={{ display: "flex", marginTop: 2 }}>
        <DataComponent
          title={t("packagesModule.packagePrice")}
          value={{ text: subscription.formattedPrice, isLink: false, target: null }}
          sx={{ width: "50%", opacity: isCanceledRecurringContract ? 0.8 : 1 }}
        />
        {isCanceledRecurringContract && (
          <DataComponent
            title={t("canceledAt")}
            value={{
              text: subscription.cancelDate,
              isLink: false,
              target: null,
            }}
            sx={{ width: "50%", opacity: isCanceledRecurringContract ? 0.8 : 1 }}
          />
        )}
        {subscription.isConsumable && (
          <DataComponent
            title={t("packagesModule.usage")}
            value={{
              text: `${subscription.currentQuantity}/${subscription.initialQuantity}`,
              isLink: false,
              target: null,
            }}
            sx={{ width: "50%" }}
          />
        )}
      </Box>
      <Box sx={{ display: "flex" }}>
        <DataComponent
          title={t("purchaseDate")}
          value={{
            text:
              subscription.cardType === "contracts"
                ? subscription.startDate
                : subscription.purchaseDate,
            isLink: false,
            target: null,
          }}
          sx={{ width: "50%", opacity: isCanceledRecurringContract ? 0.8 : 1 }}
        />
        <DataComponent
          title={t("validTill")}
          value={{ text: subscription.expires_at, isLink: false, target: null }}
          sx={{ width: "50%", opacity: isCanceledRecurringContract ? 0.8 : 1 }}
        />
      </Box>
      {subscription.cardType === "contracts" && subscription.cancelDate === null && (
        <Box sx={{ width: { xs: "100%", md: "50%", lg: "40%" } }}>
          <ThemedButton
            variant="contained"
            color="primary"
            fullWidth
            loading={isPending}
            onClick={openConfirmationModal}
          >
            {t("settingsModule.cancelSubscription")}
          </ThemedButton>
        </Box>
      )}
      <ConfirmationModal
        title={t("settingsModule.cancelSubscription")}
        description={t("settingsModule.confirmCancelSubscription", {
          name: subscription.name[i18n.language],
          expiryDate: subscription.expires_at,
        })}
        isOpen={isModalOpen}
        isPending={isPending}
        onClose={closeConfirmationModal}
        onConfirm={handleCancelSubscription}
      />
    </Box>
  );
};

SubscriptionCard.propTypes = {
  subscription: propTypes.object,
};
