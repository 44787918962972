import { useCallback } from "react";

import axios from "axios";

import { useBranding } from "../../hooks";

export const useHttpRequest = () => {
  const branding = useBranding();

  const axiosInstance = axios.create({
    baseURL: branding?.apiUrl || "",
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
  });

  const setAxiosInterceptors = useCallback(
    (token) => {
      // adding request interceptor
      axiosInstance.interceptors.request.use((config) => {
        if (token) config.headers["Authorization"] = `Bearer ${token}`;

        return config;
      });

      // adding response interceptor
      axiosInstance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          console.log(error);
          return Promise.reject(error);
        },
      );
    },
    [axiosInstance],
  );

  const clearAxiosInterceptors = useCallback(() => {
    axiosInstance.interceptors.request.clear();
    axiosInstance.interceptors.response.clear();
  }, [axiosInstance]);

  return { axiosInstance, setAxiosInterceptors, clearAxiosInterceptors };
};

export {
  getClasses,
  getClassByID,
  getTrainerByID,
  getClassBookings,
  getclassTypeTranslations,
  bookClass,
} from "./classes";
export {
  getFirstTimePackages,
  getRenewalPackages,
  getPtPackages,
  getMemberProducts,
  getproductTranslations,
  getpaymentPlanTranslations,
  getContractPayments,
} from "./packages";
export {
  getMemberProfile,
  updateMemberProfile,
  changeAccountPassword,
  getTransactionHistory,
  cancelSubscription,
  getStates,
  getCities,
  getContractCharges,
  getMemberProfilePhoto,
  updateProfilePhoto,
  registerCard,
} from "./userProfile";
