import React from "react";
import { useTranslation } from "react-i18next";

import { Alert, Typography } from "@mui/material";

const PendingPaymentAlert = () => {
  const { t } = useTranslation();

  return (
    <Alert severity="info" sx={{ backgroundColor: "customColors.bg1", marginBottom: 2 }}>
      <Typography sx={{ marginRight: 1 }} variant="body1">
        {t("dashboardModule.pendingPayment")}
      </Typography>
    </Alert>
  );
};

export default PendingPaymentAlert;
